<template>
  <div>
    <div class="list-content">
      <div class="list" v-for="(item,index) in searchList" :key="index" @click="toMap(item)">
        <img :src="item.orgLogo" alt="" />
        <div class="company-content">
          <div class="text-title">
            <span class="title">{{item.orgName}}</span>
            <span class="address">{{item.orgAddr}}</span>
            <div class="tags-list">
              <div class="tags" v-for="(value,key) in item.labelList" :key="key">
                {{value.labelName}}
              </div>
            </div>
          </div>
          <div class="btn" @click.stop="toDetail(item)">
            <span>进店</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../api'

const api = new Api()
export default {
  data() {
    return {
      data: this.$route.query,
      searchList: []
    }
  },
  mounted() {
    this.data.orgType = Number(this.data.orgType)
    this.getList()
  },
  methods: {
    getList() {
      api.getMapSearch(this.data).then(res => {
        this.searchList = res.data
      })
    },
    // 查看地图详情
    toMap(item) {
      this.$router.push({
        path: "/map",
        query: {
          orgId: item.orgId,
          orgType: item.orgType,
          latitude: item.lat,
          longitude: item.lng
        }
      });
    },
    // 跳转详情
    toDetail(item) {
      if (item.orgTypes.indexOf("3") != -1) {
        // 跳转到大板市场店铺页
        window.location.href = process.env.VUE_APP_SHOP_SERVER + '/Content/Vue/new-shop/my-shop.html?officeCode=' + item.orgId + '&isShare=2'
      } else {
        // 跳转到产业地图店铺页
        this.$router.push({
          path: "/detail",
          query: {
            orgId: item.orgId
          }
        });
      }
    }
  }
}
</script>

<style lang="less" scoped>
.list-content {
  padding: 4px 0 4px 15px;

  .list {
    // width: 100%;
    padding: 15px 15px 9px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #e8e8e8;

    img {
      width: 75px;
      height: 75px;
      border-radius: 4px;
      border: 1px solid #EBEDF0;
      margin-right: 10px;
    }
    .company-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: calc(100% - 87px);

      .text-title {
        min-width: calc(100% - 52px);
        display: flex;
        flex-direction: column;

        .title {
          font-size: 16px;
          color: #333;
          line-height: 16px;
          font-weight: normal;
          margin-bottom: 12px;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
        }
        .address {
          min-width: 208px;
          width: 100%;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          font-size: 13px;
          font-weight: 400;
          color: #ccc;
          margin-bottom: 7px;
        }
        .tags-list {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          flex-wrap: wrap;

          .tags {
            margin-right: 5px;
            margin-bottom: 7px;
            border-radius: 2px;
            border: 1px solid #DCDEE0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            font-weight: 400;
            padding: 3px 5px;
          }
        }
      }
      .btn {
        color: #666;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #DCDEE0;
        width: 50px;
        height: 28px;
        border-radius: 4px;
      }
    }
  }
}
</style>
